import React, { Component, Suspense } from 'react'
import { BrowserRouter, HashRouter, Route, Routes, useNavigate } from 'react-router-dom'
import './scss/style.scss'
import axios from 'axios'
import AuthService from './services/AuthService'
import Landing from './views/pages/Landing'

const Auth = new AuthService();

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

axios.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('5g4cap_token')
    config.headers['Authorization'] = `Bearer ${token}`
    //config.headers['Accept'] = `application/json`
    return config;
  },
  error => {
    Promise.reject(error)
});

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, async function (error) {
  const originalRequest = error.config;
  if(error.response.request.status === 401) {
    const refreshtoken = localStorage.getItem('5g4cap_refresh_token')
    if (!refreshtoken)
      window.location.pathname = "/login";
    if(!originalRequest._retry && !originalRequest.url.includes("refresh")) {
      originalRequest._retry = true;
      const resp = await Auth.refreshAccessToken();  
      const token = resp.data.access;
      Auth.setToken(token);
      return axios(originalRequest);
    } else {
      window.location.pathname = "/login";
    }
  }
  return Promise.reject(error);
});

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/" name="Landing" element={<Landing />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
