import React, { useEffect } from 'react'
import {
  CButton,
  CCol,
  CContainer,
  CFormInput,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilMagnifyingGlass } from '@coreui/icons'
import logo from '../../assets/img/5G4CAP10100_LOGO.png'
import { useNavigate } from 'react-router-dom'
import AuthService from 'src/services/AuthService'

const Landing = () => {

  const navigate = useNavigate();
  const Auth = new AuthService();

  useEffect(() => {
    setTimeout(() => {
      if(Auth.isLoggedIn())
        navigate("/dashboard");
      else
        navigate("/login")
    }, 1000)
  }, []);

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6} className='text-center'>
            <CImage src={logo} style={{maxWidth: "300px"}} />
            <br/>
            <CSpinner color='primary mt-5' />
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Landing
